import React from "react";
import { Label } from "semantic-ui-react";

export default () => {
  return (
    <Label
      circular
      color="black"
      id="stage"
      content={process.env.REACT_APP_MODE}
    />
  );
};
